<template>
  <div class="documentation-page">
    <div class="module-group">
      <div v-for="(pdf, index) in pdfs" :key="index" class="module-box">
        <a :href="pdf.url" :download="pdf.fileName" target="_blank" class="item-link">
          <div class="item-description">{{ pdf.fileName }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import documents from "@/logic/documents";
import { mapGetters } from "vuex";
import tokens from "@/logic/tokens";
import users from "@/logic/users";
import JSZip from "jszip";

export default {
  data() {
    return {
      pdfs: []
    };
  },
  created() {
    this.launchDownLoad();
  },
  methods: {
    ...mapGetters(["getUserLogged"]),
    async getLoggedUser() {
      let token = tokens.getDecodedToken();
      if (token) {
        let response = await users.getUser(token.userId);
        return response.data;
      }
      return null;
    },
    async fetchFileList() {
      await this.getLoggedUser().then(user => {
        this.loggedUser = user;
      });
      let response = await documents.getDocumentsByCompany(this.loggedUser.companyId);
      this.fileList = response.data;
    },
    getFilePath(file) {
      return `${file}`;
    },
    async downloadPDFs() {
      try {
        await this.getLoggedUser().then(user => {
          this.loggedUser = user;
        });
        const response = await documents.getDocumentsByCompany(this.loggedUser.companyId);
        const arrayBuffer = response.data;
        const zip = new JSZip();
        await zip.loadAsync(arrayBuffer);

        // Extraer los PDFs del ZIP
        const pdfs = await Promise.all(
          Object.keys(zip.files).map(async filename => {
            const file = zip.files[filename];
            if (filename.endsWith(".pdf")) {
              // Leer el contenido del archivo PDF como un blob
              const pdfBlob = await file.async("blob");
              // Convertir el blob en una URL de objeto para el archivo PDF
              const pdfUrl = URL.createObjectURL(pdfBlob, { type: "application/pdf" });
              const fileName = filename.split("/").pop();
              return { url: pdfUrl, fileName: fileName };
            }
          })
        );

        // Filtrar los resultados para eliminar los valores nulos o indefinidos
        this.pdfs = pdfs.filter(pdf => pdf !== undefined);
        // this.showDownloadMenu = true; // Mostrar el popup después de cargar los PDFs
      } catch (error) {
        console.error("Error al descargar y descomprimir los PDFs:", error);
      }
    },
    async launchDownLoad() {
      await this.downloadPDFs();
    }
  }
};
</script>

<style scoped lang="scss">
.documentation-page {
  display: flex;
  justify-content: center;
}
.documentation-page a {
  font-size: 1.5rem;
  font-weight: 500;
  color: #000;
  text-decoration: none;
}
.documentation-page ul {
  list-style: none;
}
.module-group {
  display: grid;
  @include tablet {
    grid-template-columns: repeat(3, 1fr);
  }
}
.module-box {
  margin: 10px;
  padding: 20px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #333;
}
.item-description {
  font-weight: bold;
}
.icon {
  margin-bottom: 20px;
}
</style>
