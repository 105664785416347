<template>
  <div class="account">
    <div v-if="user">
      <div class="module-box">
        <ets-module title="Datos internos">
          <p>
            Usuario: <strong>{{ user.loginName }}</strong>
          </p>
          <p>
            Email: <strong>{{ user.email }}</strong>
          </p>
          <p>
            Empresa: <strong>{{ company }}</strong>
          </p>
          <p>
            Fecha de incorporación: <strong>{{ entryDate }}</strong>
          </p>
        </ets-module>
        <ets-button text="Modificar contraseña" mode="main" @click="changePassword" />
      </div>
    </div>
    <ets-popup
      ref="changePasswordPopup"
      title="Modificar contraseña"
      v-if="showChangePasswordPopup"
      @accept="submitNewPassword"
      @close="closeChangePassword"
    >
      <div class="popup-container">
        <div class="popup-col">
          <label class="popup-label">Contraseña nueva</label>
          <ets-input v-model="newPassword" type="password" class="popup-input" />
          <label class="popup-label">Confirma la contraseña nueva</label>
          <ets-input v-model="confirmNewPassword" type="password" class="popup-input" />
          <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
          <label class="popup-label">La contraseña debe contener al menos un número y 8 caracteres.</label>
        </div>
      </div>
    </ets-popup>
  </div>
</template>

<script>
import companies from "@/logic/companies";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import tokens from "@/logic/tokens";
import users from "@/logic/users";

export default {
  data: () => ({
    user: null,
    showChangePasswordPopup: false,
    newPassword: null,
    confirmNewPassword: null,
    errorMessage: null,
    company: null
  }),
  computed: {
    entryDate() {
      return this.user.entryDate;
    }
  },
  methods: {
    ...mapGetters(["getUserLogged"]),
    async getUser() {
      let token = tokens.getDecodedToken();
      if (token) {
        let response = await users.getUser(token.userId);
        return response.data;
      }
      return null;
    },
    changePassword() {
      this.showChangePasswordPopup = true;
    },
    closeChangePassword() {
      this.showChangePasswordPopup = false;
    },
    async submitNewPassword() {
      try {
        this.errorMessage = "";
        if (this.newPassword == null) {
          this.errorMessage = "Field new password is empty";
          return;
        }
        if (this.confirmNewPassword == null) {
          this.errorMessage = "Field confirm password is empty";
          return;
        }
        if (this.newPassword != this.confirmNewPassword) {
          this.errorMessage = "Confirm password does not match new password";
          return;
        }

        const regexPassword = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;

        if (regexPassword.test(this.newPassword) && this.newPassword.length >= 8) {
          users.edit(this.user, this.newPassword);
          this.showChangePasswordPopup = false;
        } else {
          this.errorMessage = "The password must contains at leat one number and eight characters.";
          return;
        }
      } catch (error) {
        this.errorMessage = error;
        return;
      }
      this.showChangePasswordPopup = false;
    }
  },
  mounted() {
    this.getUser().then(user => {
      this.user = user;
      this.user.entryDate = dayjs(this.user.entryDate).format("DD/MM/YYYY");
    });
  },
  async created() {
    await this.getUser().then(user => {
      this.loggedUser = user;
    });
    let companyData = await companies.getCompany(this.loggedUser.companyId);
    this.company = companyData.data[0]["name"];
  }
};
</script>

<style lang="scss" scoped>
hr {
  border: 1px solid #f0f0f0;
  margin: 3rem 0;
}
.account {
  font-size: 1.5rem;
  text-align: left;
  margin: 0 auto;
  max-width: 900px;
}
.module-box {
  margin: 10px;
  padding: 20px;
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #333;
}
</style>
